import { FunctionComponent, useState, useRef, useEffect } from 'react';

import {
  Box,
  Button,
  styled,
  alpha,
  Grid,
  Popover,
  BoxProps,
} from '@mui/material';

import { useUser } from '@/lib/queries/useUser';
import api from '@/lib/waldo-api';
import { removeLocalStorageItem } from '@/ext/lib/storage';
import Avatar from '@/ext/app/ui/Avatar';
import ThemeProvider from '../providers/ThemeProvider';
import dark from '@/styles/themes/dark';

export interface ProfileMenuProps extends BoxProps {}

const SettingsMenu = styled(Popover)(({ theme }) => ({
  marginTop: theme.spacing(1.5),
}));

const AvatarButton = styled(Avatar)(() => ({
  cursor: 'pointer',
}));

const PopoverMenu = styled(Box)(({ theme }) => ({
  backgroundColor: alpha(theme.palette.common.black, 0.8),
}));

const SignOut = styled(Button)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontSize: '14px !important',
  justifyContent: 'flex-start',
  padding: theme.spacing(0.5, 0),
  border: 'none',
  '&:hover': {
    border: 'none',
    backgroundColor: 'transparent',
  },
}));

const ProfileMenu: FunctionComponent<ProfileMenuProps> = (props) => {
  const ref = useRef(null);
  const [isOpened, setIsOpened] = useState(false);
  const user = useUser();
  const [loggedIn, setLoggedIn] = useState(false);

  const openPopover = () => setIsOpened(true);
  const closePopover = () => setIsOpened(false);

  useEffect(() => {
    setLoggedIn(!!user);
  }, [user]);

  const handleLogout = async () => {
    setIsOpened(false);
    await api.user.logout();
    removeLocalStorageItem('sync-user');
    setLoggedIn(false);
    window.location.href = '/sign-in';
  };

  if (!loggedIn) {
    return null;
  }

  return (
    <Box {...props} ref={ref}>
      <AvatarButton data-cy="profile-button" onClick={openPopover} size={32} />
      <SettingsMenu
        anchorEl={ref.current}
        open={isOpened}
        onClose={closePopover}
      >
        <ThemeProvider noBaseline theme={dark}>
          <PopoverMenu py={1} width="16rem">
            <Grid container direction="column">
              <Grid item>
                <Box
                  alignItems="center"
                  display="flex"
                  justifyContent="space-between"
                  px={1.5}
                >
                  <SignOut data-cy="sign-out-button" onClick={handleLogout}>
                    Sign out
                  </SignOut>
                </Box>
              </Grid>
            </Grid>
          </PopoverMenu>
        </ThemeProvider>
      </SettingsMenu>
    </Box>
  );
};

export default ProfileMenu;
