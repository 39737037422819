import { FunctionComponent } from 'react';

import Head from 'next/head';
import { alpha, Box, Grid, Link, styled, Typography } from '@mui/material';

import ProfileMenu from '@/components/ProfileMenu';
import { Waldo } from '@/ext/app/icons/Waldo';
import { WaldoSadGlasses } from '@/ext/app/icons/WaldoSadGlasses';
import ButtonPrimary from '@/ext/app/ui/ButtonPrimary';
import { useUser } from '@/lib/queries/useUser';
import ThemeProvider from '@/components/providers/ThemeProvider';

export interface ErrorProps {
  errorDescription: string;
  errorNumber: number;
}

const Container = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  color: '#FFFFFF',
  display: 'flex',
  flexDirection: 'column',
  height: '100vh',
  marginTop: theme.spacing(4),
  textAlign: 'center',
}));

const ErrorContainer = styled(Box)(({ theme }) => ({
  alignItems: 'flex-end',
  display: 'flex',
  justifyContent: 'center',
  marginTop: theme.spacing(8),
  position: 'relative',
  height: theme.spacing(13.5),
  width: theme.spacing(25),
}));

const ErrorTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.icon?.disabled,

  '&.MuiTypography-root': {
    fontSize: 128,
    fontWeight: 800,
    lineHeight: 1,
  },
}));

const ErrorDescription = styled(Typography)(({ theme }) => ({
  whiteSpace: 'pre-line',
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(6.3),
  marginTop: theme.spacing(1.7),
}));

const LoginButton = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.disabled,
  marginTop: theme.spacing(1.5),

  '&.MuiTypography-root': {
    fontSize: 16,
    letterSpacing: '-2%',
    lineHeight: 2,
  },
}));

const ProfileButton = styled(ProfileMenu)(({ theme }) => ({
  position: 'absolute',
  right: theme.spacing(1),
  top: theme.spacing(1),
  zIndex: 1200,

  [theme.breakpoints.up('md')]: {
    right: theme.spacing(2),
  },
}));

const WaldoGlasses = styled(WaldoSadGlasses)(({ theme }) => ({
  color: alpha(
    theme.palette.mode === 'dark' ? '#FFFFFF' : theme.palette.fill?.high || '',
    0.3,
  ),
  position: 'absolute',
  height: theme.spacing(5.5),
  width: theme.spacing(7.7),
  right: 0,
  top: 0,
}));

const WaldoLogo = styled(Waldo)(({ theme }) => ({
  color: theme.palette.icon?.disabled,
  height: theme.spacing(1.9),
  width: theme.spacing(7.5),
}));

const Error: FunctionComponent<ErrorProps> = ({
  children,
  errorDescription,
  errorNumber,
}) => {
  const user = useUser();

  return (
    <ThemeProvider>
      <Head>
        <title>{`Waldo / ${errorNumber}`}</title>
      </Head>
      <ProfileButton />
      <Grid container spacing={2} justifyContent="center">
        <Grid item>
          <Container>
            <WaldoLogo />
            <ErrorContainer>
              <WaldoGlasses />
              <ErrorTitle>{errorNumber}</ErrorTitle>
            </ErrorContainer>
            <ErrorDescription display="block" variant="h5">
              {errorDescription}
            </ErrorDescription>
            {!user && errorNumber !== 500 && (
              <>
                <Link style={{ textDecoration: 'none' }} href="/">
                  <ButtonPrimary
                    sx={{
                      fontSize: 20,
                      fontWeight: 400,
                      letterSpacing: '-2%',
                      lineHeight: 1.6,
                      paddingInline: 2.5,
                    }}
                  >
                    Try Waldo now - it's free
                  </ButtonPrimary>
                </Link>
                <Link style={{ textDecoration: 'none' }} href="/sign-in">
                  <LoginButton>Login to Waldo</LoginButton>
                </Link>
              </>
            )}
            {children}
          </Container>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

export default Error;
