import { FunctionComponent, useEffect } from 'react';
import { useRouter } from 'next/router';
import type { NextPage } from 'next';
import type { AppProps } from 'next/app';
import Head from 'next/head';

import '../styles/global.css';
import ErrorBoundary from '@/error/ErrorBoundary';
import { storeUtmTags } from '@/lib/utils';
import Dependencies from '@/components/providers/Dependencies';
import { useTracking } from '@/ext/app/state/tracking';
import { TrackableEvent } from '@/ext/lib/trackable';
import { inIframe } from '@/utils/inIframe';
import { setLocalStorageItem } from '@/ext/lib/storage';
import { isExtensionInstalled } from '@/ext/app/state/engine';
import { EXTENSION_INSTALLED_KEY } from '@/constants';

const isProduction = process.env.NODE_ENV === 'production';

type AppPropsWithLayout = AppProps & {
  Component: NextPage;
};

const App: FunctionComponent<AppProps> = ({
  Component,
  pageProps,
}: AppPropsWithLayout) => {
  const router = useRouter();
  const trackEvent = useTracking();
  const { query } = router;

  // Navigation tracking.
  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (router.pathname === '/notepad' && inIframe()) {
        return;
      }
      trackEvent(TrackableEvent.NAVIGATION, {
        target: router.pathname,
      });
    }
  }, [router.pathname, trackEvent]);

  // 💩💩💩💩
  // Since AWS Amplify redirect strips out our query params
  // We are manually redirecting from waldo.fyi to www.waldo.fyi
  // 💩💩💩💩
  useEffect(() => {
    const url = window ? window.location.href : '';

    if (/^https?:\/\/waldo\.fyi.*$/g.test(url)) {
      const newUrl = url.replace('waldo.fyi', 'www.waldo.fyi');
      router.push(newUrl);
    }
  }, []);

  useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side');
    jssStyles?.parentElement?.removeChild(jssStyles);
    // Record that the extension is installed.
    isExtensionInstalled().then((installed) => {
      if (installed) {
        setLocalStorageItem(EXTENSION_INSTALLED_KEY, true);
      }
    });
  }, []);

  // Manage UTM tags.
  useEffect(() => {
    storeUtmTags(query);
  }, [query]);

  // Manage Facebook Pixel.
  useEffect(() => {
    if (isProduction) {
      import('react-facebook-pixel')
        .then((x) => x.default)
        .then((ReactPixel) => {
          ReactPixel.init('1005877900148878');
          ReactPixel.pageView();

          router.events.on('routeChangeComplete', () => {
            ReactPixel.pageView();
          });
        });
    }
  }, [router.events]);

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width"
        />
      </Head>
      <Dependencies>
        <ErrorBoundary>
          <Component {...pageProps} />
        </ErrorBoundary>
      </Dependencies>
    </>
  );
};

export default App;
