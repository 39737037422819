/* eslint-disable max-classes-per-file,class-methods-use-this */
import * as Sentry from '@sentry/nextjs';

import { isUndefined, omitBy } from 'lodash/fp';
import qs from 'querystring';

import { sendMessage } from '../app/utils/sendBackgroundMessage';
import { WaldoAPIRequestParams } from '../types';
import { ensure } from '../utils/ensure';

export interface EnhancedResponse<R> {
  data: R;
  headers: Record<string, string>;
}

export interface APIAdapter {
  requestEnhanced<R, B = undefined>(
    params: WaldoAPIRequestParams<B>,
  ): Promise<EnhancedResponse<R>>;
}

export class WaldoApiError extends Error {
  status: number;

  data?: Record<string, unknown>;

  constructor(message: string, status: number, data?: Record<string, unknown>) {
    const extendedMessage = `${message} (${status})`;
    super(extendedMessage);
    this.name = 'WaldoApiError';
    this.status = status;
    this.data = data;

    if (this.data) {
      Sentry.setContext('data', this.data);
    }
  }
}

export class HttpAdapter implements APIAdapter {
  constructor(readonly host = ensure(process.env.NEXT_PUBLIC_API_BASE)) {}

  public async requestEnhanced<R, B = undefined>({
    method,
    path,
    query,
    body,
    options,
  }: WaldoAPIRequestParams<B>): Promise<EnhancedResponse<R>> {
    const url = new URL(
      this.host.includes('localhost') ? `/local${path}` : path,
      this.host,
    );

    if (query) {
      const params = qs.encode(omitBy(isUndefined, query));
      url.search = params;
    }
    const headers = new Headers();
    if (body) {
      headers.set('content-type', 'application/json');
    }
    const response = await fetch(url.toString(), {
      method,
      body: body !== undefined ? JSON.stringify(body) : undefined,
      headers,
      credentials: 'include',
      keepalive: true,
      ...options,
    });

    if (!response.ok) {
      if (response.headers.get('Content-Type')?.includes('application/json')) {
        const error = await response.json();
        throw new WaldoApiError(error.message, response.status, {
          url: url.toString(),
          method,
          body: body || undefined,
        });
      } else {
        try {
          const text = await response.text();
          throw new WaldoApiError(
            `WaldoApiError: "${text}" (${response.status} - ${path})`,
            response.status,
          );
        } catch (e: any) {
          throw new WaldoApiError(
            `WaldoApiError: (${response.status} - ${path})`,
            response.status,
          );
        }
      }
    }

    const contentType = response.headers.get('content-type');
    const data = await (contentType ? response.json() : undefined);

    return {
      data,
      headers: Object.fromEntries(response.headers.entries()),
    };
  }
}
export class ServiceWorkerAdapter implements APIAdapter {
  public async requestEnhanced<R, B>(
    value: WaldoAPIRequestParams<B>,
  ): Promise<EnhancedResponse<R>> {
    return sendMessage({
      type: 'WALDO_API_REQUEST',
      value,
    });
  }
}

export const isInThirdPartyFrame = (): boolean => {
  try {
    return (
      typeof window !== 'undefined' &&
      window.parent !== window &&
      !window.parent.location.href
    );
  } catch (e) {
    return true;
  }
};

export const getDefaultAdapter = (): APIAdapter =>
  isInThirdPartyFrame() ? new ServiceWorkerAdapter() : new HttpAdapter();

export class WaldoAPI {
  constructor(protected adapter: APIAdapter = getDefaultAdapter()) {}

  public async requestEnhanced<R, B = undefined>(
    params: WaldoAPIRequestParams<B>,
  ): Promise<EnhancedResponse<R>> {
    return this.adapter.requestEnhanced(params);
  }

  public async request<R, B = undefined>(
    options: WaldoAPIRequestParams<B>,
  ): Promise<R> {
    const { data } = await this.requestEnhanced<R, B>(options);
    return data;
  }
}
