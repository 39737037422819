import { Suspense } from 'react';
import { NextPage } from 'next';
import * as Sentry from '@sentry/nextjs';

import Error from './Error';
import ButtonPrimary from '@/ext/app/ui/ButtonPrimary';
import { useIsExtensionInstalled } from '@/ext/app/state/engine';
import { isBrowserSupported } from '@/ext/app/utils/isBrowserSupported';
import NotSupportedError from './NotSupportedError';

const ErrorBoundary: NextPage = ({ children }) => {
  const refreshPage = (resetError: () => void) => () => {
    resetError();
    window.location.reload();
  };

  return (
    <Sentry.ErrorBoundary
      fallback={({ resetError }) => {
        const extensionInstalled = useIsExtensionInstalled();
        const browserSupported = isBrowserSupported();

        const notSupportedError = !browserSupported && extensionInstalled;
        return (
          <Suspense fallback={null}>
            {notSupportedError ? (
              <NotSupportedError />
            ) : (
              <Error
                errorDescription="Oops, something went wrong. Please refresh the page and try again"
                errorNumber={500}
              >
                <ButtonPrimary
                  onClick={refreshPage(resetError)}
                  sx={{
                    fontSize: 20,
                    fontWeight: 400,
                    letterSpacing: '-2%',
                    lineHeight: 1.6,
                    paddingInline: 2.5,
                  }}
                >
                  Refresh page
                </ButtonPrimary>
              </Error>
            )}
          </Suspense>
        );
      }}
    >
      {children}
    </Sentry.ErrorBoundary>
  );
};

export default ErrorBoundary;
