import {
  atom,
  selector,
  SetterOrUpdater,
  useRecoilCallback,
  useRecoilValue,
  useRecoilValueLoadable,
  useSetRecoilState,
} from 'recoil';
import { isBrowserSupported } from '../utils/isBrowserSupported';

import { localStorageEffect } from '../utils/recoil';
import { sendMessage } from '../utils/sendBackgroundMessage';

/* Supported Search Engines */
export type SearchEngine = 'google' | 'bing';

/**
 * Is Extension Installed (allowing for the use of Google)
 */
export const isExtensionInstalled = async (): Promise<boolean> =>
  sendMessage({ type: 'PING' })
    .then(() => true)
    .catch(() => false);

/**
 * Extension Installed State
 */
export const extensionInstalledState = atom<boolean>({
  key: 'extensionInstalledState',
  default: isExtensionInstalled(),
});

export const useIsExtensionInstalled = () =>
  useRecoilValue(extensionInstalledState);

export const useIsExtensionInstalledSync = () =>
  useRecoilValueLoadable(extensionInstalledState).valueMaybe() || false;

export const useSetExtensionInstalled = () =>
  useSetRecoilState(extensionInstalledState);

/**
 * Saved Search Engine State
 *
 * - The source engine to use for the search
 */
export const savedSearchEngineState = atom<SearchEngine>({
  key: 'savedSearchEngineState',
  default: 'google',
  effects: [localStorageEffect],
});

/**
 * Default Search Engine State
 *
 * - The source engine to use for the search
 */
export const defaultSearchEngineState = selector<SearchEngine>({
  key: 'defaultSearchEngineState',
  get: ({ get }) =>
    get(extensionInstalledState) && isBrowserSupported()
      ? get(savedSearchEngineState)
      : 'bing',
  set: ({ get, set }, value) => {
    if (get(extensionInstalledState) && isBrowserSupported()) {
      set(savedSearchEngineState, value);
    }
  },
});

export const useDefaultSearchEngine = (): SearchEngine =>
  useRecoilValue(defaultSearchEngineState);

export const useSetDefaultSearchEngine = (): SetterOrUpdater<SearchEngine> =>
  useSetRecoilState(defaultSearchEngineState);

export const useToggleDefaultSearchEngine = (): (() => void) =>
  useRecoilCallback(
    ({ set, snapshot }) =>
      () => {
        const newSourceEngine =
          snapshot.getLoadable(defaultSearchEngineState).getValue() === 'google'
            ? 'bing'
            : 'google';
        set(defaultSearchEngineState, () => newSourceEngine);
      },
    [],
  );
