import { alpha, darken, lighten, Theme, ThemeOptions } from '@mui/material';

import { RadioButton } from '@/ext/app/icons/RadioButton';
import { RadioButtonChecked } from '@/ext/app/icons/RadioButtonChecked';
import { CheckboxChecked } from '@/ext/app/icons/CheckboxChecked';
import { Checkbox } from '@/ext/app/icons/Checkbox';

export const getOverrides = ({
  breakpoints,
  palette,
  spacing,
  typography,
}: Theme): ThemeOptions => ({
  palette: {
    divider: palette.line?.disabled,

    infoDark: {
      contrastText: palette.common.white,
      main: palette.info?.dark,
      dark: darken(palette.info?.dark, 0.1),
      light: lighten(palette.info?.dark, 0.1),
    },

    infoLight: {
      contrastText: palette.common.white,
      main: palette.info?.light,
      dark: darken(palette.info?.light, 0.1),
      light: lighten(palette.info?.light, 0.1),
    },
  },

  components: {
    MuiAlert: {
      styleOverrides: {
        root: {
          alignItems: 'center',
          gap: spacing(0.5),
          padding: spacing(0.5, 1),
        },

        icon: {
          margin: 0,
          padding: 0,
        },

        message: {
          padding: 0,
        },

        standard: {
          background: 'none',
        },
      },
    },

    MuiAutocomplete: {
      styleOverrides: {
        option: {
          padding: '0.75rem',
        },
        paper: {
          display: 'block',
          ...typography.body1,
        },
        clearIndicator: {
          color: palette.icon?.disabled,
        },

        root: {
          '& .MuiFilledInput-root.MuiInputBase-sizeSmall': {
            paddingBottom: spacing(0.5),
          },
        },
      },
    },

    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },

    MuiButton: {
      styleOverrides: {
        root: {
          '&$disabled': {
            cursor: 'not-allowed',
            color: palette.disabled,
          },
        },

        contained: {
          boxShadow: 'none',
          padding: spacing(0.5, 1),
        },

        containedPrimary: {
          '&:hover': {
            backgroundColor: palette.primary.light,
          },
        },

        containedSecondary: {
          '&:hover': {
            backgroundColor: palette.secondary.light,
          },
        },

        // TODO: eventually remove this...
        containedTertiary: {
          color: palette.tertiary?.contrastText,

          '&:hover': {
            backgroundColor: palette.tertiary?.dark,
          },
        },

        containedWarning: {
          color: palette.warning.contrastText,

          '&:hover': {
            backgroundColor: palette.warning.main,
          },
        },

        outlined: {
          padding: spacing(0.5, 1),
        },

        // TODO: eventually remove this...
        outlinedNeutral: {
          '&:not(.Mui-disabled)': {
            // Need to define this here because on base there's no neutral color defined
            color: palette.neutral?.dark,
            '& > *': {
              color: palette.neutral?.main,
            },
          },
        },

        outlinedSecondary: {
          color: palette.secondary.contrastText,
        },

        startIcon: {
          '&.MuiButton-iconSizeSmall': {
            '& > :nth-of-type(1)': {
              fontSize: typography.pxToRem(16),
            },
          },

          '&.MuiButton-iconSizeMedium': {
            '& > :nth-of-type(1)': {
              fontSize: typography.pxToRem(24),
            },
          },

          '&.MuiButton-iconSizeLarge': {
            '& > :nth-of-type(1)': {
              fontSize: typography.pxToRem(32),
            },
          },
        },

        text: {
          minWidth: 0,
          padding: 0,

          '&:hover': {
            backgroundColor: 'transparent',
          },
        },

        textNeutral: {
          '&:not(.Mui-disabled)': {
            color: palette.fill.high,
          },
        },
      },

      defaultProps: {
        color: 'neutral',
        disableRipple: true,
        variant: 'outlined',
      },
    },

    MuiChip: {
      styleOverrides: {
        root: {
          '&.MuiChip-colorDefault': {
            '&:not(.MuiChip-outlined)': {
              backgroundColor: palette.fill.disabled,
            },

            '&:hover': {
              backgroundColor: palette.fill.medium,
            },
          },

          '&.MuiChip-colorPrimary': {
            backgroundColor: palette.primary.main,

            '&:hover': {
              backgroundColor: palette.primary.light,
            },
          },
        },
      },
    },

    MuiCssBaseline: {
      styleOverrides: {
        '*:focus, *:focus-visible': {
          outline: 'none',
        },

        '*::selection': {
          background: palette.primary.main,
          color: palette.primary.contrastText,
        },

        '*:not(body)::-webkit-scrollbar': {
          height: 0,
          width: 0,
        },

        '*:not(body):hover::-webkit-scrollbar': {
          height: spacing(0.5),
          width: spacing(0.5),
        },

        ':not(body)::-webkit-scrollbar-corner': {
          background: 'transparent',
        },

        ':not(body)::-webkit-scrollbar-thumb': {
          borderRadius: spacing(0.5),
          backgroundColor: alpha(palette.text.primary, 0.5),

          '&:hover': {
            backgroundColor: alpha(palette.text.primary, 0.6),
          },
        },

        '*': {
          boxSizing: 'border-box',
        },

        'body.googleElement': {
          background: 'transparent',
          height: '100vh',
          overflow: 'hidden',
          width: '100vw',

          '#__next': {
            height: '100%',
          },
        },
      },
    },

    MuiCheckbox: {
      defaultProps: {
        icon: <Checkbox />,
        checkedIcon: <CheckboxChecked />,
      },

      styleOverrides: {
        root: {
          '& svg': {
            fontSize: 24,
          },

          '&.Mui-checked': {
            '& svg': {
              '> path:nth-of-type(1)': {
                fill: palette.helper,
              },
            },
          },
        },
      },
    },

    MuiDialog: {
      styleOverrides: {
        container: {
          overflow: 'hidden',
        },

        paper: {
          maxWidth: spacing(35),
          padding: spacing(1.5, 2),
          overflowY: 'initial',
          width: spacing(35),
        },
      },
    },

    MuiDialogActions: {
      styleOverrides: {
        root: {
          ...(typography.subtitle1 as Record<string, string>),
          color: palette.primary.main,
          padding: 0,
          paddingBlockStart: spacing(1.5),
        },
      },
    },

    MuiDialogContent: {
      styleOverrides: {
        root: {
          color: palette.text.secondary,
          padding: 0,
          overflow: 'overlay',
          overflowX: 'hidden',
        },
      },
    },

    MuiDialogTitle: {
      styleOverrides: {
        root: {
          paddingBlockEnd: spacing(1.5),
          paddingBlockStart: 0,
          paddingInline: 0,
          ...typography.h3,
        },
      },
    },

    MuiFilledInput: {
      styleOverrides: {
        root: {
          backgroundColor: palette.fill?.disabled,
          borderRadius: spacing(0.5),
          padding: spacing(0.5),

          '&:after, &:before': {
            display: 'none',
          },

          '&:hover, &.Mui-focused': {
            backgroundColor: palette.fill?.medium,
          },
        },

        input: {
          padding: 0,
        },
      },
    },

    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: palette.text.primary,

          '&.Mui-focused': {
            color: palette.text.primary,
          },
        },
      },
    },

    MuiIconButton: {
      styleOverrides: {
        root: {
          '&:disabled': {
            color: palette.icon?.disabled,
          },
        },

        sizeSmall: {
          margin: spacing(-0.25),
          padding: spacing(0.25),
        },
      },
    },

    MuiInputBase: {
      styleOverrides: {
        root: {
          ...typography.body1,
          color: palette.text.primary,
          padding: spacing(0.875, 1),
        },

        input: {
          height: spacing(1.5),
        },
      },
    },

    MuiLink: {
      styleOverrides: {
        root: {
          '&:visited': {
            color: palette.info.main,
          },
        },
      },

      defaultProps: {
        color: 'inherit',
      },
    },

    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: spacing(2),
        },
      },
    },

    MuiListItemText: {
      styleOverrides: {
        root: {
          marginBottom: spacing(0.5),
          marginTop: spacing(0.5),
        },
      },
    },

    MuiModal: {
      styleOverrides: {
        root: {
          display: 'flex',
          flexDirection: 'column',
        },
      },
    },

    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: spacing(0.5),

          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: palette.line?.medium,
            borderWidth: 1,
          },

          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: palette.line?.high,
            borderWidth: 1,
          },
        },

        input: {
          padding: 0,
        },

        notchedOutline: {
          borderColor: palette.line?.disabled,
        },
      },
    },

    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundImage: 'none',
        },
      },
    },

    MuiPopover: {
      defaultProps: {
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },

        transformOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
      },
    },

    MuiRadio: {
      defaultProps: {
        icon: <RadioButton />,
        checkedIcon: <RadioButtonChecked />,
      },
    },

    MuiSelect: {
      styleOverrides: {
        select: {
          '&:focus': {
            backgroundColor: 'transparent',
          },
        },

        icon: {
          color: palette.text.primary,
        },
      },

      defaultProps: {
        color: 'secondary',
        displayEmpty: true,
      },
    },

    MuiSkeleton: {
      defaultProps: {
        animation: 'wave',
      },
    },

    MuiSnackbar: {
      styleOverrides: {
        root: {
          fontSize: 12,
        },
      },
    },

    MuiSvgIcon: {
      styleOverrides: {
        colorDisabled: {
          color: palette.icon?.disabled,
        },

        colorHigh: {
          color: palette.icon?.high,
        },

        colorMedium: {
          color: palette.icon?.medium,
        },

        fontSizeSmall: {
          fontSize: typography.pxToRem(16),
        },

        fontSizeLarge: {
          fontSize: typography.pxToRem(32),
        },
      },
    },

    MuiSwitch: {
      styleOverrides: {
        root: {
          height: spacing(1.5),
          overflow: 'visible',
          padding: 0,
          width: spacing(3),

          '&.MuiSwitch-sizeSmall': {
            '& .Mui-checked': {
              transform: `translateX(${spacing(1.5)})`,
            },

            '& .MuiSwitch-switchBase': {
              padding: 0,
            },

            '& .MuiSwitch-thumb': {
              height: spacing(1.25),
              width: spacing(1.25),
            },

            [breakpoints.up('md')]: {
              height: spacing(1),
              width: spacing(1.75),

              '& .Mui-checked': {
                transform: `translateX(${spacing(0.75)})`,
              },

              '& .MuiSwitch-thumb': {
                height: spacing(0.75),
                width: spacing(0.75),
              },

              '& .MuiSwitch-track': {
                borderRadius: spacing(0.5),
              },
            },
          },
        },

        switchBase: {
          margin: 2,
          padding: 0,

          '&.Mui-checked': {
            transform: `translateX(${spacing(1.5)})`,
          },

          '&.Mui-checked + .MuiSwitch-track': {
            opacity: 1,
          },

          '&.Mui-disabled': {
            opacity: 0.5,
          },
        },

        thumb: {
          backgroundColor: palette.common.white,
          height: spacing(1.25),
          width: spacing(1.25),
        },

        track: {
          backgroundColor: palette.fill?.medium,
          borderRadius: spacing(1.5),
          opacity: 1,
        },
      },

      defaultProps: {
        size: 'small',
      },
    },

    MuiTabs: {
      styleOverrides: {
        root: {
          borderBottom: '1px solid',
          minHeight: spacing(2),
        },
        indicator: {
          bottom: 0,
          height: 2,
        },
        scrollButtons: {
          width: spacing(2),
        },
      },
      defaultProps: {
        indicatorColor: 'primary',
        textColor: 'primary',
      },
    },

    MuiTab: {
      styleOverrides: {
        root: {
          margin: 0,
          marginRight: '2rem',
          minWidth: 0,
          padding: 0,
          '&:last-child': {
            marginRight: 0,
          },
          [breakpoints.up('sm')]: {
            minWidth: 0,
          },
          '&$selected': {
            '&:hover': {
              opacity: 1,
            },
          },
        },
        textColorInherit: {
          '&:hover': {
            opacity: '0.85',
          },
        },
      },
    },

    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: palette.background?.contrast,
          borderRadius: spacing(0.5),
          boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.25)',
          color: palette.text?.contrast,
          padding: '0.5rem 1rem',
        },
      },
    },

    MuiTextField: {
      defaultProps: {
        color: 'primary',
      },
    },

    MuiTypography: {
      defaultProps: {
        variant: 'body1',
        variantMapping: {
          body1: 'div',
          body2: 'div',
          subtitle1: 'div',
          subtitle2: 'div',
        },
      },
    },
  },
});
